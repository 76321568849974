import { FLOW_STATE } from '../../constants';
import { ActionType } from '../actionTypes';
import { OrganizationLicense } from '../organization/types';


export interface Profile {
  id: number;
  user: number;
  user_leader: number;
  company: string;
  industry: number;
  industry_display: string;
  role: string;
  about_me: string;
  valid_pic_url: string;
  referral_url: string;
  country: number;
  country_code: string;
  location_display: string;
  postal_code: string;
  state: string;
  state_short: string;
  city: string;
  expertise_areas: string[];
  source: '' | null;
  moderator: boolean;
  app_onboarding_completed: boolean;
  reference_type: ReferenceType | null;
}

export enum ReferenceType {
  BLOG_POST = "BLOG_POST",
  GOOGLE_SEARCH = "GOOGLE_SEARCH",
  COMPANY_USER = "COMPANY_USER",
  WORD_OF_MOUTH = "WORD_OF_MOUTH",
  SCHEDULING_LINK = "SCHEDULING_LINK",
  EVENT = "EVENT",
  CABINET_NEWSLETTER = "CABINET_NEWSLETTER",
  EMAIL_FROM_CABINET = "EMAIL_FROM_CABINET",
  LINKEDIN_MESSAGE_FROM_CABINET = "LINKEDIN_MESSAGE_FROM_CABINET",
  OTHER = "OTHER",
}

interface UserStats {
  id: number;
  points: number;
  top_contributor: boolean;
}

export interface AccountOwner {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export interface UserPrefs {
  id: number;
  user: number;
  task_time_track_remind: boolean;
  profile_searchable: boolean;
  due_email: boolean;
  due_push: boolean;
  digest_email: boolean;
  digest_push: boolean;
  digest_all: boolean;
}

export interface CabinetFeatures {
  COLLABORATION?: boolean;
  PROFILES?: boolean;
  TODO_ANALYTICS?: boolean;
  TODO?: boolean;
  WHITELABEL_LOGO?: boolean;
  MEETING_POLLS?: boolean;
  ZOOM?: boolean;
  EXEC_HOLDS?: boolean;
  ANONYMOUS_POLLS?: boolean;
  PREVENT_ADD_PARTICIPANTS?: boolean;
  MEETING_QUESTIONS?: boolean;
  REUSABLE_MEETINGS?: boolean;
  PREREGISTERED_ATTENDEES?: boolean;
  HIDE_PROMO?: boolean;
  MULTI_TIMEZONE?: boolean;
  POLL_RESULTS_DAY_SPLIT?: boolean;
  CALENDAR_ANALYTICS?: boolean;
  ALLOW_MULTIPLE_POLL_VOTERS?: boolean;
  BLOCK_CALENDAR_ANALYTICS?: boolean;
  SALESFORCE?: boolean;
  ALPHA?: boolean;
  BETA?: boolean;
  INTERNAL_ONLY?: boolean;
  MEETING_FILES?: boolean;
  ADVANCE_NOTICE?: boolean;
  OTHER_CALENDARS?: boolean;
  DISABLE_EVENT_DESCRIPTION?: boolean;
  OLD_MEETING_SHARING?: boolean;
  UNPAID_LICENSE?: boolean;
  MULTI_LEADER_CALENDAR_VIEW?: boolean;
  SUPPORT_MAP?: boolean;
  BUFFER_TIME?: boolean;
  MEETING_ORGANIZER_OVERRIDE?: boolean;
  PARTICIPANT_REMINDERS?: boolean;
  CRM?: boolean;
  SINGLE_SELECT_QUESTIONS?: boolean;
  SHOW_DELEGATE_UI?: boolean;
  DISABLE_USER_LEADER_CONTROL?: boolean;
  HOLD_DESCRIPTION_PREFERENCE?: boolean;
}

export interface CabinetPermissions {
  STRIPE_SUBSCRIPTION_MGMT?: boolean;
  ORGANIZATION_LICENSE_TABLE?: boolean;
  CONTENT_EDITOR?: boolean
  ANALYTICS_LABELS?: boolean
  READ_LEADER_DATA_ACCESS?: boolean
  WRITE_LEADER_DATA_ACCESS?: boolean
}

export interface OAuthGrantDetails {
  scope: string[];
  account_user_id: string;
  user_id: number;
  token_id: number;
}

export interface AllOAuthGrantDetails {
  [provider: string]: {
    [username: string]: OAuthGrantDetails;
  }
}

export enum IdentityProvider {
  COGNITO = "cognito",
  GOOGLE = "google",
  CUSTOM = "custom"
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  profile: Profile;
  stats: UserStats;
  active_license: OrganizationLicense;
  licenses: {[id: string]: OrganizationLicense}
  prefs: UserPrefs;
  features: CabinetFeatures;
  permissions: CabinetPermissions;
  oauth_grant_details: AllOAuthGrantDetails;
  identity_provider: IdentityProvider
  tos_acceptance_required: boolean
}

export interface OAuthProvider {
  id: number;
  name: string;
}



export interface Auth {
  flowState: FLOW_STATE
  challenge: AuthChallenge;
  isAuthenticated: boolean;
  isLoading: boolean;
  isRegistrationSaving: boolean;
  isOnboardingInitialized: boolean;
  // grants: { [id: Grant['id']]: Grant };
  user: User | null | undefined;
  email: string | null | undefined;
  showCongrats: boolean;
  showOnboarding: boolean;
  moderator: boolean;
  redirectUrl: string | null;
}

export interface AuthChallenge {
  name: string;
  session: string;
  params: {[key: string]: string};
}

export interface AuthChallengeResponse {
  name: string;
  session: string;
  responses: {[key: string]: string};
}

export interface Grant {
  // id: number;
  provider: string;
  account_user_id?: string;
  username: string;
  user_id: number;
  scope: string[];
  signedIn: boolean;
  token_id: number;
}

export type ZoomUserContactsSearchResult = {
  zoom_user_contacts: Array<{
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    job_title: string;
    location: string;
  }>
};

export type ZoomUserSchedulersSearchResult = {
  zoom_user_schedulers: {
    [account_id: string]: {
      schedulers: Array<{
        id: string, 
        email: string, 
        pmi: number
      }>
    }
  };
  has_shared_permissions: boolean;
  has_basic_permissions: boolean;
};

export type OAuthToken = {
  id: number;
  provider: number;
  username: string;
};

export type CognitoUserInfo = {
  id?: string 
  username: string,
  attributes: {
    email: string,
    family_name: string,
    given_name: string,
    email_verified: boolean,
    sub: string
  }
};

export type IdentityResponse = {
  detail: string | null,
  code: "login-with-sso"
} | {
  detail: "Use Google",
  code: "login-with-google" 
} | {
  detail: "Use email",
  code: "login-with-email"
};

export type AuthAction = 
  { type: ActionType.NO_TOKEN } |
  { type: ActionType.USER_LOADING } | 
  { type: ActionType.UPDATE_ONBOARDING_COMPLETED; completed: boolean; } | 
  { type: ActionType.UPDATE_REFERENCE_TYPE; referenceType: ReferenceType | null; } | 
  { type: ActionType.AUTHENTICATION_ERROR } | 
  { type: ActionType.LOGIN_FAILED } | 
  { type: ActionType.LOGOUT_SUCCESSFUL } |
  { type: ActionType.ACCEPTED_TOS } |
  { type: ActionType.USER_LOADED; user: User; email: string; showOnboarding: boolean } |
  { type: ActionType.UPDATED_USER_PROFILE; userData: { first_name: string; last_name: string; }; profile: Profile } |
  { type: ActionType.UPDATED_USER_PREFS; userPrefs: UserPrefs} |
  { type: ActionType.UPDATED_USER_ACTIVE_LICENSE; license: Partial<OrganizationLicense> } |
  { type: ActionType.LOGIN_SUCCESSFUL; user: User; stayLoggedIn: boolean; showOnboarding: boolean; } |
  { type: ActionType.PASSWORD_CHANGE_REQUIRED } |
  { type: ActionType.PASSWORD_CHANGE_SUCCESFUL } | 
  { type: ActionType.PASSWORD_CHANGE_CANCELLED } |
  { type: ActionType.REGISTRATION_INCOMPLETE } |
  { type: ActionType.SAVED_OAUTH_GRANT; provider: string; username: string; grant_details: OAuthGrantDetails} |
  { type: ActionType.LOGGED_OUT_OAUTH; provider: string, username: string} |
  { type: ActionType.SET_AUTH_REDIRECT; url: string | null} |
  { type: ActionType.ONBOARDING_INITIALIZED; isOnboardingInitialized: boolean};
